import * as React from "react"
import ContactWithoutImage from "../components/contact-without-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const KontaktPage = () => (
  <Layout>
    <Seo title="Home" />
    <ContactWithoutImage />
  </Layout>
)

export default KontaktPage
