import React from "react"

import { useState } from "react"
import { Switch } from "@headlessui/react"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function ContactWithoutImage() {
  const [agreed, setAgreed] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    let myForm = document.getElementById("form-contact")
    let formData = new FormData(myForm)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => console.log("Form successfully submitted"))
      .catch(error => alert(error))
  }

  return (
    <div className="bg-white py-4 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
      <div className="relative max-w-xl mx-auto">
        <svg
          className="absolute left-full transform translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <svg
          className="absolute right-full bottom-0 transform -translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>

        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-4">
          Kontaktformular
        </h1>

        <form
          name="form-contact"
          data-netlify="true"
          onSubmit={handleSubmit}
          data-netlify-honeypot="bot-field"
          method="POST"
          className="grid grid-cols-1 gap-y-6
          sm:grid-cols-2 sm:gap-x-8"
        >
          <div>
            <label
              htmlFor="user_firstname"
              className="block text-sm font-medium text-gray-700"
            >
              Vorname
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="user_firstname"
                id="user_firstname"
                autoComplete="given-name"
                className="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="user_lastname"
              className="block text-sm font-medium text-gray-700"
            >
              Nachname
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="user_lastname"
                id="user_lastname"
                autoComplete="family-name"
                className="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="user_company"
              className="block text-sm font-medium text-gray-700"
            >
              Firma
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="user_company"
                id="user_company"
                autoComplete="organization"
                className="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="user_email"
              className="block text-sm font-medium text-gray-700"
            >
              E-Mail
            </label>
            <div className="mt-1">
              <input
                id="user_email"
                name="user_email"
                type="user_email"
                autoComplete="user_email"
                className="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Telefon / Mobile
            </label>
            <div className="mt-1">
              <input
                id="user_phone"
                name="user_phone"
                type="user_phone"
                autoComplete="user_phone"
                className="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="user_message"
              className="block text-sm font-medium text-gray-700"
            >
              Ihre Nachricht
            </label>
            <div className="mt-1">
              <textarea
                id="user_message"
                name="user_message"
                rows={4}
                className="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md"
                defaultValue={""}
              />
            </div>
          </div>
          <div className="sm:col-span-2 hidden">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <Switch
                  checked={agreed}
                  onChange={setAgreed}
                  className={classNames(
                    agreed ? "bg-blue-600" : "bg-gray-200",
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  )}
                >
                  <span className="sr-only">Agree to policies</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      agreed ? "translate-x-5" : "translate-x-0",
                      "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                    )}
                  />
                </Switch>
              </div>
            </div>
          </div>
          <div className="sm:col-span-2">
            <input type="hidden" name="form-name" value="form-contact" />

            <button
              type="submit"
              className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Nachricht absenden
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
